import React from 'react';
import {
  AreaChart, 
  Area, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  ResponsiveContainer
} from 'recharts';

const colors = ["#8884d8", "#82ca9d", "#ffc658"];

class ReturnChart extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      idx: 0,
      data: [],
      keys: []
    };

    this.handleTimeControl = this.handleTimeControl.bind(this);
  }

  componentDidMount() {
    let { data, keys } = this.prepareInputFormatToPlot(this.props.eth_prices, this.props.all_day_returns, 365);
    const keysArr = Array.from(keys);
    this.setState({ data: data, keys: keysArr });
  }

  // The function accepts raw data, calculate daily return in USD and popular a dailyReturn map in USD
  prepareInputFormatToPlot(eth_prices, dailyReturnData, numDayToPlot) {
    let keys = new Set();
    let data = [];
    for (let i = 0; i < numDayToPlot; i++) {
      let dayData = dailyReturnData[i]
      let mapDic = {}
      mapDic['date'] = String(i + 1) + 'd ago';

      if (dayData && dayData.length > 0) {
        for (let j = 0; j < dayData.length; j++) {
          let token_address = dayData[j]['token']['address'];
          let token_display = dayData[j]['token']['display_name'];
          let token_decimals = dayData[j]['token']['decimals'];
          let token_amt = dayData[j]['amt'] / Math.pow(10.0, token_decimals);
          mapDic[token_display] = eth_prices[token_address] * token_amt;
          keys.add(token_display);
        }
      }

      // make a deep copy of the map and push it to data
      data.unshift(mapDic);
    }
    // Fill in missing data
    data.forEach(mapDic => {
      keys.forEach(key => {
        if (!(key in mapDic)) {
          mapDic[key] = 0.0;
        }
      });
    });
    return { data: data, keys: keys };
  }

  handleTimeControl(e, j) {
    e.preventDefault();
    this.setState({ idx: j });
  }

  render() {
    let areaEls = [];
    for (var i = 0; i < this.state.keys.length; i++) {
      let color = colors[i % colors.length];
      areaEls.push(<Area
        key={i}
        type="monotone"
        dataKey={this.state.keys[i]}
        stackId={1}
        stroke={color}
        fill={color}
      />);
    }

    let timeOptions = [
      { key: '7d', data: this.state.data.slice(-7) },
      { key: '15d', data: this.state.data.slice(-15) },
      { key: '30d', data: this.state.data.slice(-30) },
      { key: '90d', data: this.state.data.slice(-90) },
      { key: '1y', data: this.state.data.slice(-365) },
    ];
    let timeControlEls = [];
    for (var i = 0; i < timeOptions.length; i++) {
      let j = i;
      timeControlEls.push(<li key={i} className={this.state.idx == i ? "is-active" : ""}>
        <a href="#" onClick={ e => this.handleTimeControl(e, j) }>{timeOptions[i].key}</a>
      </li>);
    }
    
    return (
      <div>
        <nav className="breadcrumb has-dot-separator is-small is-right" aria-label="breadcrumbs">
          <ul>
            {timeControlEls}
          </ul>
        </nav>
        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
          <AreaChart
            width={500}
            height={400}
            data={timeOptions[this.state.idx].data}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="date" />
            <YAxis />
            <Tooltip />
            {areaEls}
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
    );
  }
}

export default ReturnChart;