import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class InfoPopup extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Popup style={{
        maxHeight: '100vh',
        overflowY: 'auto'
      }} ref={e => { this.popup = e; }} trigger={<a href="#">How does this work?</a>} position="right center" closeOnDocumentClick modal>
        <div className="box columns">
          <div className="column">
            <h1 className="is-size-3 title has-text-centered">How does this work?</h1>
            <p className="is-size-5">This is a simple tool that scans publicly-available on-chain transactions to estimate Return-on-Investment (ROI) for your wallet. As this is limited to on-chain data, tokens that are not-yet claimed will not be factored into this calculation. For the most accurate results, claim any tokens from the game interface before using this tool.</p>
            <br />

            <h1 className="is-size-4 title">How is ROI Calculated?</h1>
            <p className="is-size-5">Generally, we compute ROI by dividing your returns by your expenses. We categorize your returns as one of two types: NFT earnings (sale price - purchase price for sold game-related NFTs) and fungible token returns (e.g. SLP returns for Axie Infinity). We categorize your expenses as one of two types: NFT expenses (total amount spent on all game-related NFTs) and fungible token expenses (e.g. AXS spent to breed Axies).</p>
            <br />

            <h1 className="is-size-4 title">How is Payback Period Calculated?</h1>
            <p className="is-size-5">Payback is the amount of time needed to recoup your investment (including time already spent). We compute this by dividing your total expenses by your total returns (fungible token returns + total amount gained from selling NFTs), then multiplying this ratio with the amount of time since your first game-related transaction.</p>
            <br />

            <h1 className="is-size-4 title">Does Gas Impact ROI?</h1>
            <p className="is-size-5">Currently, gas fees are not incorporated into the ROI / payback calculations. This is something that we will add in future updates.</p>
          </div>
        </div>
      </Popup>
    );
  }
}

export default InfoPopup;