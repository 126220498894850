import React from 'react';
import ReturnChart from './ReturnChart';
import PopulationChart from './PopulationChart';

/** Render token amounts in a table. */
function TokenTable(props) {
  var els = [];
  for (var i = 0; i < props.token_amounts.length; i++) {
    let token_amount = props.token_amounts[i];
    let token = token_amount.token;
    let amount = token_amount.amt / Math.pow(10, token.decimals);
    let rate = props.eth_prices[token.address];
    els.push((
      <tr key={i}>
        <td><abbr title={token.address}>
          {token.display_name}
        </abbr></td>
        <td>{amount}</td>
        <td>{rate ? amount * rate : "--"}</td>
      </tr>
    ));
  }

  return (
    <div className="table-container">
      <table className="table is-fullwidth is-hoverable is-striped">
        <thead>
          <tr>
            <th>Token</th>
            <th>Amount</th>
            <th>ETH Value</th>
          </tr>
        </thead>
        <tbody>
          {els}
        </tbody>
      </table>
    </div>
  );
}

class ROIOutput extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      idx: 0,
      roi: this.props.roi || 0,
      payback: this.props.payback || 0,
      invested_amts: this.props.invested_amts || [],
      return_amts: this.props.return_amts || [],
      start_date: this.props.start_date || null,
      end_date: this.props.end_date || null,
      eth_prices: this.props.eth_prices || {},
      all_day_returns: this.props.all_day_returns || {},
    };

    this.onTabSelect = this.onTabSelect.bind(this);
  }

  onTabSelect(idx) {
    this.setState({ idx: idx });
  }

  createTabs() {
    return [{
      key: "Overview",
      el: (
        <div>
          <div className="panel-block column p-5">
            {(this.state.start_date  && this.state.end_date) &&
              <p className="is-size-6 mb-2">Based on your average returns over the time period {this.state.start_date} to {this.state.end_date}:</p>
            }
            <div className="has-text-centered columns">
              <div className="column">
                <p className="is-size-3 has-text-primary"><b>{this.state.roi.toFixed(2)}%</b></p>
                <p>ROI</p>
              </div>
              <div className="column">
                <p className="is-size-3 has-text-primary"><b>{this.state.payback.toFixed(2)}</b></p>
                <p>Payback Period (days)</p>
              </div>
            </div>
          </div>
          <div className="panel-block column p-5">
            <h2 className="title is-size-4 has-text-primary">Recent Returns <span className="is-size-6">(ETH Value)</span></h2>
            <ReturnChart eth_prices={this.state.eth_prices} all_day_returns={this.state.all_day_returns}  />
          </div>
        </div>
      )
    }, {
      key: "Expenses / Returns",
      el: (
        <div className="panel-block column p-5">
          <h2 className="title is-size-4 has-text-primary">Total Expenses</h2>
          <TokenTable
            token_amounts={this.state.invested_amts}
            eth_prices={this.state.eth_prices}
          />
          <hr />
          <h2 className="title is-size-4 has-text-primary">Total Returns</h2>
          <TokenTable
            token_amounts={this.state.return_amts}
            eth_prices={this.state.eth_prices}
          />
        </div>
      )
    }, {
      key: "Other Players",
      el: (
        <div className="panel-block column p-5">
          <h2 className="title is-size-4 has-text-primary">Playerbase Paybacks</h2>
          <PopulationChart />
        </div>
      )
    }];
  }
  
  render() {
    let tabs = this.createTabs();
    let tabLabels = [];
    for (var i = 0; i < tabs.length; i++) {
      let label = tabs[i].key;
      let k = i;
      tabLabels.push(
        <li
          key={i}
          className={i == this.state.idx ? "is-active" : ""}
          onClick={() => this.onTabSelect(k)}
        >
          <a><span>{label}</span></a>
        </li>
      );
    }
    return (
      <article className="panel is-primary">
        {/* Header */}
        <p className="panel-heading is-size-6">Results</p>

        {/* Tab Buttons */}
        <div className="tabs is-centered m-0">
          <ul>{tabLabels}</ul>
        </div>

        {/* Tab Contents */}
        {tabs[this.state.idx].el}
      </article>
    );
  }
}

export default ROIOutput;