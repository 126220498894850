import React from 'react';
import {
  XAxis,
  Bar,
  BarChart,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import './PopulationChart.css';

const data = [
{
    payback: '4.95',
    count: 35,
  }, {
    payback: '129.40',
    count: 16,
  }, {
    payback: '253.85',
    count: 8,
  }, {
    payback: '378.30',
    count: 4,
  }, {
    payback: '502.75',
    count: 6,
  }, {
    payback: '627.20',
    count: 2,
  }, {
    payback: '751.65',
    count: 1,
  }, {
    payback: '876.10',
    count: 0,
  }, {
    payback: '1000.55',
    count: 1,
  }, {
    payback: '1125.00',
    count: 0,
  }, {
    payback: '1249.45',
    count: 2,
  }, {
    payback: '1373.90',
    count: 0,
  }, {
    payback: '1498.35',
    count: 0,
  }, {
    payback: '1622.80',
    count: 0,
  }, {
    payback: '1747.25',
    count: 3,
  },
];

class PopulationChart extends React.Component {

  render() {
    // TODO: User Percentile
    return (
      <div>
        {/* Chart */}
        <div style={{ height: "300px" }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data}>
              <XAxis label={{ value: 'Payback Period (days)', dy: 20 }} dataKey="payback" />
              <Tooltip />
              <Bar dataKey="count" fill="#8884d8" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        {/* Aggregate Stats */}
        <div className="columns has-text-centered mt-3">
          <div className="column">
            <p>Mean (days): <b className="has-text-primary">316.425</b></p>
          </div>
          <div className="column">
            <p>SD (days): <b className="has-text-primary">409.203</b></p>
          </div>
        </div>
        <hr />
        <p className="is-size-7 has-text-centered">Based on a random sample of 78 recently-active players (updated 4/26/22)</p>
      </div>
    );
  }
}

export default PopulationChart;