import React from 'react';

class ROIInput extends React.Component {

  constructor(props) {
    super(props);

    // Contains the field names and current values.
    this.values = {
      address: "0x0000000000000000000000000000000000000000",
      game: "axie"
    }

    this.state = {
      disabled: this.props.inProgress || false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    this.values[target.name] = target.value;
  }

  handleSubmit(event) {
    event.preventDefault();
    // TODO: Input validation
    this.props.onSubmit(this.values);
  }

  render() {
    return (
      <form id="roi-form" className="column block" onSubmit={this.handleSubmit}>
        {/* Row of inputs */}
        <div className="columns is-multiline is-mobile">
          <div className="column">
            {/* Wallet Input Field */}
            <div className="field">
              <label className="label">Wallet Address</label>
              <div className="control has-icons-left">
                <input
                  id="roi-form-address"
                  className="input" 
                  type="text"
                  name="address"
                  placeholder="0x0000000000000000000000000000000000000000"
                  onChange={this.handleInputChange} />
                <span className="icon is-small is-left">
                  <i className="fab fa-ethereum" aria-hidden="true"></i>
                </span>
              </div>
            </div>
          </div>
          
          <div className="column">
            {/* Game Dropdown */}
            <div className="field">
              <label className="label">Game (Chain)</label>
              <div className="control">
                <div className="select is-fullwidth">
                  <select
                    id="roi-form-game"
                    name="game"
                    onChange={this.handleInputChange}>
                    <option value="axie">Axie Infinity (Ronin)</option>
                    {/* Only support AXIE for now for simplified testing. /*/}
                    <option value="mobox" disabled>Mobox</option>
                    <option value="crypto-blade" disabled>Cryptoblades</option>
                    <option value="alien-worlds" disabled>Alien Worlds</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Submit Button */}
        <div className="control">
          <button type="submit" className="button is-primary is-fullwidth" disabled={this.state.disabled}>
            Calculate
          </button>
        </div>
      </form>
    );
  }
}

export default ROIInput;
