import React from 'react';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';

class EmailPopup extends React.Component {

  constructor(props) {
    super(props);

    this.values = {
      "email": undefined
    };

    this.state = {
      loading: false
    };

    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    this.values[target.name] = target.value;
  }

  handleSubmit(event) {
    event.preventDefault();

    // Start loading
    this.setState({ loading: true });

    // Submit email
    let EMAIL_ENDPOINT = `${process.env.REACT_APP_API_URL}/follow`;
    fetch(EMAIL_ENDPOINT, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'}, 
      body: JSON.stringify(this.values)
    }).then(() => {
      // Stop loading and close
      this.setState({ loading: false });
      this.popup.close();
    });
  }

  render() {
    return (
      <Popup ref={e => { this.popup = e; }} trigger={<a href="#">Follow</a>} position="right center" defaultOpen="true" closeOnDocumentClick modal>
        <div className="box columns">
          <div className="column">
            <h1 className="is-size-3 title has-text-centered"> Connect With Us! </h1>
            <p className="is-size-5 has-text-centered"><span className="has-text-weight-medium">Receive information about Gamefi projects and airdrops</span></p>
            <p className="is-size-5 has-text-centered">and <span className="has-text-weight-medium">join a community of Gamefi investors</span></p>
    
            {/* Form */}
            <form className="mt-1" onSubmit={this.handleSubmit}>
              <div className="column">
                <input
                  type="email"
                  className="input"
                  name="email"
                  placeholder="Email"
                  onChange={this.handleInputChange}
                  required
                />
                <button
                  type="submit"
                  className={`button is-primary is-fullwidth ${this.state.loading ?? "is-loading"}`}
                  disabled={this.state.loading}>
                  Subscribe
                </button>
              </div>
            </form>
          </div>
        </div>
      </Popup>
    );
  }
}

export default EmailPopup;