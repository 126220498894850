import React from 'react';
import EmailPopup from './EmailPopup';
import InfoPopup from './InfoPopup';

class NavBar extends React.Component {

  render() {
    return (
      <nav className="navbar is-transparent">
        <div className="navbar-brand">
          <a className="navbar-item" href="https://insider.fyi">
            <h1 className="is-size-4 is-primary">Insider</h1>
          </a>
        </div>

        <div className="navbar-menu is-active">
          <div className="navbar-end">
            <div className="navbar-item">
              {/* Menu bar buttons */}
              <nav className="breadcrumb is-centered" aria-label="breadcrumbs">
                <ul>
                  <li><EmailPopup>Email Subscription</EmailPopup></li>
                  <li><a href="https://forms.gle/dgZYsGvU6qv3MaTE6">Submit Feedback</a></li>
                  <li><InfoPopup>Info</InfoPopup></li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default NavBar;