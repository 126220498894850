import React from 'react';
import NavBar from './components/NavBar';
import ROIInput from './components/ROIInput';
import ROIOutput from './components/ROIOutput';

import './App.sass';

class App extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      output: null,
      inProgress: false,
      error: null
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleErrorDismiss = this.handleErrorDismiss.bind(this);
  }

  /**
   * Dismisses the error notifier.
   */
  handleErrorDismiss() {
    this.setState({ error: null });
  }

  /**
   * Callback for `ROIInput`, where `values` is
   * a dictionary mapping field name -> user input value.
   */
  onSubmit(values) {
    this.setState({
      output: null,
      inProgress: true,
      error: null
    });

    // Call API to get ROI
    let ROI_ENDPOINT = `${process.env.REACT_APP_API_URL}/roi/${values.game}/${values.address}`;
    fetch(ROI_ENDPOINT)
      .then(response => {
        if (!response.ok) {
          throw new Error(response.statusText);
        }
        return response.json();
      })
      .then(data => {
        this.setState({
          inProgress: false,
          output: {
            roi: data.roi,
            payback: data.payback / 86400.0, // Convert from seconds to days
            invested_amts: data.expenses,
            return_amts: data.returns,
            start_date: data.start_date,
            end_date: data.end_date,
            eth_prices: data.eth_prices,
            all_day_returns: data.all_day_returns,
        }});
      })
      .catch(error => {
        this.setState({
          error: error.toString(),
          inProgress: false
        });
      });
  }

  render() {
    return (
      <section className="section columns is-centered">
        <div className="column is-half">
          <NavBar />

          {/* Input form */}
          <ROIInput
            inProgress={this.state.inProgress}
            onSubmit={this.onSubmit} />

          {/* Error notifier */}
          {this.state.error &&
            <div className="notification is-danger">
              <button className="delete" onClick={this.handleErrorDismiss}></button>
              {this.state.error}
            </div>
          }

          {/* Progress bar and output */}
          {this.state.inProgress ?
            <progress className="progress is-small is-primary" max="100"></progress> :
            this.state.output &&
              <ROIOutput {...this.state.output} />
          }
        </div>
      </section>
    );
  }
}

export default App;
